import { Modal, Box, Typography, Button } from "@mui/material";

export default function RestrictedCampaign({ openRestrictedModal, setOpenRestrictedModal }) {
  return (
    <Modal
      open={openRestrictedModal}
      onClose={() => setOpenRestrictedModal(false)}
      aria-labelledby="restricted-modal"
    >
      <Box
        sx={{
          padding: 3,
          backgroundColor: "#FFFFFF",
          width: 350,
          maxHeight: 450,
          marginX: "auto",
          marginTop: 20,
          borderRadius: 4,
          overflow: "scroll",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column"
        }}
      >
      <Typography
        variant="h6"
        style={{ marginBottom: 20 }}
        align="center"
      >
      Please upload and complete <br/>
      your insights & gallery before <br/>
      applying for campaigns.
      </Typography>
      <Button
          variant="contained"
          onClick={() => setOpenRestrictedModal(false)}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
}
