import React, { Fragment, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Typography,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import S3 from "aws-s3";
import "react-advanced-cropper/dist/style.css";
import s3_config from "../../../config/s3_config";
import { useMutation } from "@apollo/client";
import SUBMIT_TASK from "../../../graphql/mutations/submitTask";
import {imageQuality} from "../../../components/shared/imageQuality"

window.Buffer = window.Buffer || require("buffer").Buffer;
const S3Client = new S3(s3_config);

const StyledVideo = styled("video")({
  top: 50,
  width: "80%",
  height: "80%",
  objectFit: "cover",
  position: "absolute",
});

export const TaskUploader = ({ open, setOpen, id }) => {
  const [picture, setPicture] = useState("");
  const [videoFile, setVideoFile] = useState(null);
  const [disableActions, setDisableActions] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [submitTask] = useMutation(SUBMIT_TASK, {
    onCompleted: () => {
      setUploading(false);
      setOpen(0);
    },
    refetchQueries: ["influencerGetTasks"],
  });

  const handleSave = async () => {
    if (picture !== "") {
      submitTask({
        variables: {
          taskId: id,
          imageSubmission: picture,
        },
      });
    } else {
      submitTask({
        variables: {
          taskId: id,
          videoSubmission: videoFile,
        },
      });
    }
  };

  const handleClose = () => {
    setPicture("");
    setVideoFile(null);
    setDisableActions(true);
    setUploading(false);
    setOpen(0);
  };

  const handleImageUpload = async (file) => {
    setUploading(true);
    const isGoodQuality = await imageQuality(file);
    if (!isGoodQuality) {
      setUploading(false);
      alert('Please upload a better quality image file.');
    }
    else{
    S3Client.uploadFile(file)
      .then((data) => {
        setPicture(data.location);
        setUploading(false);
        setDisableActions(false);
      })
      .catch((err) => {});
    }
  };

  const handleVideoUpload = async (file) => {
    setUploading(true);
    S3Client.uploadFile(file)
      .then((data) => {
        setVideoFile(data.location);
        setUploading(false);
        setDisableActions(false);
      })
      .catch((err) => {});
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      {uploading ? (
        <Grid
          item
          xs
          container
          sx={{ padding: 5 }}
          justifyContent="center"
          align="center"
          direction="column"
        >
          <CircularProgress title="Uploading" sx={{ margin: "auto" }} />
          <Typography variant="subtitle1" color="primary">
            Uploading
          </Typography>
        </Grid>
      ) : (
        <>
          <DialogTitle align="center">Upload Item</DialogTitle>
          {picture !== "" && (
            <DialogContent>
              <img src={picture} alt="preview" width="400px" height="auto" />
            </DialogContent>
          )}
          {videoFile && (
            <DialogContent
              sx={{
                width: 400,
                height: 600,
                marginBottom: 4,
                display: "flex",
                alignItems: "center",
              }}
            >
              <StyledVideo
                src={videoFile}
                type="video/mp4"
                // autoPlay
                muted
                controls
                // loop
              />
            </DialogContent>
          )}
          <DialogActions>
            <LoadingButton
              variant="contained"
              component="label"
              sx={{ marginRight: 4 }}
              disabled={!disableActions}
            >
              Select Image
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={(e) => handleImageUpload(e.target.files[0])}
              />
            </LoadingButton>
            <LoadingButton
              variant="contained"
              component="label"
              sx={{ marginRight: 4 }}
              disabled={!disableActions}
            >
              Select Video
              <input
                hidden
                accept="video/*"
                type="file"
                onChange={(e) => handleVideoUpload(e.target.files[0])}
              />
            </LoadingButton>
            <Button onClick={handleSave} disabled={disableActions}>
              Upload
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
