import { useState } from "react";
// @mui
import {
  Stack,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

import Iconify from "../../components/iconify";
import { ProfilePicUploader } from "./ProfilePicUploader";
import { useQuery, useMutation } from "@apollo/client";
import GET_CAMPAIGN_CATEGORIES from "../../graphql/queries/getCampaignCategories";
import FINISH_SIGNUP from "../../graphql/mutations/finishSignup";
import SIGNUP from "../../graphql/mutations/signup";

// ----------------------------------------------------------------------
const countries = ["England", "Northern Ireland", "Scotland", "Wales"];
const cities = {
  England: [
    "Bath",
    "Birmingham",
    "Bradford",
    "Brighton and Hove",
    "Bristol",
    "Cambridge",
    "Canterbury",
    "Carlisle",
    "Chelmsford",
    "Chester",
    "Chichester",
    "Colchester",
    "Coventry",
    "Derby",
    "Doncaster",
    "Durham",
    "Ely",
    "Exeter",
    "Gloucester",
    "Hereford",
    "Kingston upon Hull",
    "Lancaster",
    "Leeds",
    "Leicester",
    "Lichfield",
    "Lincoln",
    "Liverpool",
    "London",
    "Manchester",
    "Milton Keynes",
    "Newcastle upon Tyne",
    "Norwich",
    "Nottingham",
    "Oxford",
    "Peterborough",
    "Plymouth",
    "Portsmouth",
    "Preston",
    "Ripon",
    "Salford",
    "Salisbury",
    "Sheffield",
    "Southampton",
    "Southend-on-Sea",
    "St Albans",
    "Stoke-on-Trent",
    "Sunderland",
    "Truro",
    "Wakefield",
    "Wells",
    "Westminster",
    "Winchester",
    "Wolverhampton",
    "Worcester",
    "York",
  ],
  "Northern Ireland": [
    "Armagh",
    "Bangor",
    "Belfast",
    "Lisburn",
    "Londonderry",
    "Newry",
  ],
  Scotland: [
    "Aberdeen",
    "Dundee",
    "Dunfermline",
    "Edinburgh",
    "Glasgow",
    "Inverness",
    "Perth",
    "Stirling",
  ],
  Wales: [
    "Bangor",
    "Cardiff",
    "Newport",
    "St Asaph (Llanelwy)",
    "St Davids",
    "Swansea",
    "Wrexham",
  ],
};
const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
export default function SignupForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [fields, setFields] = useState({
    userName: "",
    email: "",
    password: "",
    facebook: "",
    instagram: "",
    tiktok: "",
    category_id: 0,
  });
  const [profilePic, setProfilePic] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [openPp, setOpenPp] = useState(false);
  const navigate = useNavigate();
  function disableIt() {
    return (
      fields.userName === "" ||
      fields.email === "" ||
      fields.password === "" ||
      selectedCountry === "" ||
      selectedCity === "" ||
      profilePic === null ||
      !isEmail(fields.email)
    );
  }

  const { data: categories } = useQuery(GET_CAMPAIGN_CATEGORIES);
  const [finishSignup] = useMutation(FINISH_SIGNUP, {
    onCompleted: () => {
      navigate("/login");
    },
  });

  const [signup] = useMutation(SIGNUP, {
    onCompleted: (res) => {
      finishSignup({
        variables: {
          id: res.userRegister.authenticatable.id,
          name: fields.userName,
          country: selectedCountry,
          city: selectedCity,
          instagram: fields.instagram,
          tiktok: fields.tiktok,
          facebook: fields.facebook,
          image: profilePic,
          categories: [fields.category_id],
        },
      });
    },
  });

  const handleSubmit = () => {
    signup({
      variables: {
        email: fields.email,
        password: fields.password,
        passwordConfirmation: fields.password,
      },
    });
  };
  return (
    <>
      <Stack spacing={3}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoComplete="name"
          autoFocus
          onChange={(e) => setFields({ ...fields, userName: e.target.value })}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={(e) => setFields({ ...fields, email: e.target.value })}
        />
        <TextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          onChange={(e) => setFields({ ...fields, password: e.target.value })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          margin="normal"
          fullWidth
          id="instagram"
          label="Instagram Handle"
          name="instagram"
          placeholder="authentic"
          autoComplete="instagram"
          autoFocus
          onChange={(e) => setFields({ ...fields, instagram: e.target.value })}
        />
        <TextField
          margin="normal"
          fullWidth
          id="tiktok"
          placeholder="@authentic"
          label="TikTok Handle"
          name="tiktok"
          autoComplete="tiktok"
          autoFocus
          onChange={(e) => setFields({ ...fields, tiktok: e.target.value })}
        />
        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <InputLabel id="demo-simple-select-label">Select Niche</InputLabel>
          <Select
            labelId="campaign-category-select-label"
            id="campaign-category-select"
            label="Campaign Category"
            value={fields.category_id}
            onChange={(e) =>
              setFields({ ...fields, category_id: e.target.value })
            }
          >
            {categories?.getCampaignCategories?.map((category) => (
              <MenuItem
                value={category.id}
                key={category.id + "category-type" + category.id}
              >
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Campaign"
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            {countries.map((country, index) => (
              <MenuItem value={country} key={`${country}-${index}`}>
                {country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedCountry && (
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <InputLabel id="demo-simple-select-label">Select City</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Campaign"
              onChange={(e) => setSelectedCity(e.target.value)}
            >
              {cities[selectedCountry].map((city, index) => (
                <MenuItem value={city} key={`${city}-${index}`}>
                  {city}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Button variant="outlined" onClick={() => setOpenPp(true)}>
          Upload Image
        </Button>
        {openPp && (
          <ProfilePicUploader
            open={openPp}
            setOpen={setOpenPp}
            setPfp={setProfilePic}
          />
        )}
        <img
          src={profilePic}
          width="100"
          height="100"
          style={{ margin: "auto", paddingTop: 10 }}
          alt="profile-img"
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleSubmit}
        sx={{ marginTop: 3 }}
        disabled={disableIt()}
      >
        Signup
      </LoadingButton>
    </>
  );
}
