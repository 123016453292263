import { gql } from "@apollo/client";

const SUBMIT_CAMPAIGN_RESULTS = gql`
  mutation submitCampaignResults(
    $userId: ID!
    $campaignId: ID!
    $instaImpressions: Int
    $instaLikes: Int
    $instaComments: Int
    $instaFollowers: Int
    $instaStoryViews: Int
    $instaReach: Int
    $instaPlays: Int
    $tiktokLikes: Int
    $tiktokComments: Int
    $tiktokFollowers: Int
    $tiktokViews: Int
    $tiktokShares: Int
    $discountCodeUsed: Boolean
    $instaNonFollowers: Int
    $instaSaves: Int
    $instaShares: Int
    $instaProfileVisits: Int
    $instaAccountsReached: Int
    $instaAccountsEngaged: Int
    $instaLinkClicks: Int
    $campaignResultStatus: String!
    $campaignResultMedia: String!
    $tiktokAverageWatchTime: String!
    $tiktokWatchFullVideo: String!
    $tiktokPlays: Int
    $tiktokSaves: Int
    $link: [String!]!
  ) {
    submitCampaignResults(
      userId: $userId
      campaignId: $campaignId
      instaImpressions: $instaImpressions
      instaLikes: $instaLikes
      instaComments: $instaComments
      instaFollowers: $instaFollowers
      instaStoryViews: $instaStoryViews
      instaReach: $instaReach
      instaPlays: $instaPlays
      tiktokLikes: $tiktokLikes
      tiktokComments: $tiktokComments
      tiktokFollowers: $tiktokFollowers
      tiktokViews: $tiktokViews
      tiktokShares: $tiktokShares
      discountCodeUsed: $discountCodeUsed
      instaNonFollowers: $instaNonFollowers
      instaSaves: $instaSaves
      instaShares: $instaShares
      instaProfileVisits: $instaProfileVisits
      instaAccountsReached: $instaAccountsReached
      instaAccountsEngaged: $instaAccountsEngaged
      instaLinkClicks: $instaLinkClicks
      campaignResultStatus: $campaignResultStatus
      campaignResultMedia: $campaignResultMedia
      tiktokAverageWatchTime: $tiktokAverageWatchTime
      tiktokWatchFullVideo: $tiktokWatchFullVideo
      tiktokPlays: $tiktokPlays
      tiktokSaves: $tiktokSaves
      link: $link
    )
  }
`;

export default SUBMIT_CAMPAIGN_RESULTS;
