import { gql } from "@apollo/client";

const SUBMIT_INSTAGRAM_STATS = gql`
  mutation submitInstagramStat(
    $userId: ID!
    $link: [String!]!
    $following: Int!
    $followingGrowthRate: String
    $topCountry: String
    $topCountryPercentage: Int
    $topCity: String
    $topCityPercentage: Int
    $femaleRatio: Int
    $maleRatio: Int
    $accountsEngaged: Int
    $accountsEngagedGrowthRate: String
    $accountVisibility: Int
    $accountsReached: Int
    $accountsReachedGrowthRate: String
    $topAgeRange: String
  ) {
    submitInstagramStat(
      userId: $userId
      link: $link
      following: $following
      followingGrowthRate: $followingGrowthRate
      topCountry: $topCountry
      topCountryPercentage: $topCountryPercentage
      topCity: $topCity
      topCityPercentage: $topCityPercentage
      femaleRatio: $femaleRatio
      maleRatio: $maleRatio
      accountsEngaged: $accountsEngaged
      accountsEngagedGrowthRate: $accountsEngagedGrowthRate
      accountVisibility: $accountVisibility
      accountsReached: $accountsReached
      topAgeRange: $topAgeRange
      accountsReachedGrowthRate: $accountsReachedGrowthRate
    )
  }
`;

export default SUBMIT_INSTAGRAM_STATS;
