import React from "react";
import { Chip, Grid, Stack, Typography, Button } from "@mui/material";
import useStyles from "./styles";
import { useQuery } from "@apollo/client";
import GET_ACTIVE_CAMPAIGN from "../../../../graphql/queries/getActiveCampaign";
import HowToVideoPopup from "../../../../components/HowToVideoPopup";
import { formatDate } from "../../../../components/dateFormatter";
import DownloadContract from "../../../../components/DownloadContract";

const statusColor = {
  In_Progress: "#FFA3C7",
  Approved: "#6ab04c",
  Pending: "#f9ca24",
};
const statusName = {
  In_Progress: "In-Progress",
  Approved: "Approved",
  Pending: "Waiting Approval",
};

const CampaignDetailBanner = (props) => {
  const { campaignId } = props;
  const classes = useStyles();

  const { data } = useQuery(GET_ACTIVE_CAMPAIGN, {
    fetchPolicy: "network-only",
    variables: {
      campaignId: campaignId,
    },
  });

  function getCampaignFee(contracts) {
    let fee = 0;
    contracts.forEach((contract) => {
      if (
        contract.userId.toString() ===
        localStorage.getItem("currentUser").toString()
      ) {
        fee = `£ ${contract.finalFee}`;
      }
    });

    return fee;
  }

  return (
    <Grid
      item
      xs={12}
      container
      className={classes.campaignDetailBanner}
      justifyContent="start"
      alignItems="center"
    >
      <Grid item xs={12} sm={3} sx={{ marginTop: "4px" }}>
        <img
          src={data?.influencerGetActiveCampaign.coverPic}
          alt={data?.influencerGetActiveCampaign.name + "-campaign cover"}
          className={classes.coverImage}
        />
      </Grid>
      <Grid item xs={12} sm={9} container justifyContent="space-between">
        <Grid
          item
          xs={12}
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          mb={2}
        >
          <Typography variant="h4">
            {data?.influencerGetActiveCampaign.name}
          </Typography>
          &emsp;
          <Typography
            variant="caption"
            sx={{
              color: "#ffffff",
              backgroundColor:
                statusColor[data?.influencerGetActiveCampaign.status],
              padding: "1px 10px",
              borderRadius: "10px",
              marginRight: "15px",
            }}
          >
            {statusName[data?.influencerGetActiveCampaign.status]}
          </Typography>
          <HowToVideoPopup
            title="How to upload your content to brands"
            description="Watch the video below to learn how to upload your content for brand approval."
            youtubeId="4nF3KXpE6rE"
          />
          <DownloadContract campaign={data} />
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="space-around"
          sx={{ overflow: "scroll" }}
        >
          <Stack
            direction="column"
            sx={{
              marginTop: "10px",
              marginRight: "10px",
              alignItems: "center",
            }}
          >
            <Stack
              direction="column"
              sx={{ marginTop: "10px", marginRight: "10px" }}
            >
              <Chip
                label="Campaign Media"
                size="medium"
                variant="outlined"
                sx={{ width: "140px", borderColor: "#FFA3C7" }}
              />
              <Chip
                label={data?.influencerGetActiveCampaign.media}
                size="medium"
                color="success"
                sx={{
                  width: "140px",
                  marginTop: "8px",
                  backgroundColor: "#FFA3C7",
                  fontWeight: 700,
                }}
              />
            </Stack>

            <Stack
              direction="column"
              sx={{
                marginTop: "10px",
                marginRight: "10px",
                alignItems: "center",
              }}
            >
              <Chip
                label="Campaign Category"
                size="medium"
                variant="outlined"
                sx={{ width: "160px", borderColor: "#FFA3C7" }}
              />
              <Chip
                label={data?.influencerGetActiveCampaign.category.name}
                size="medium"
                color="success"
                sx={{
                  width: "250px",
                  marginTop: "8px",
                  backgroundColor: "#FFA3C7",
                  fontWeight: 700,
                }}
              />
            </Stack>
          </Stack>
          <Stack
            direction="column"
            sx={{
              marginTop: "10px",
              marginRight: "10px",
              alignItems: "start",
              maxWidth: "100%",
            }}
          >
            <Stack
              direction="column"
              sx={{
                marginTop: "10px",
                marginRight: "10px",
                alignItems: "center",
              }}
            >
              <Chip
                label="Campaign Type"
                size="medium"
                variant="outlined"
                sx={{ width: "140px", borderColor: "#FFA3C7" }}
              />
              <Chip
                label={data?.influencerGetActiveCampaign.campaignType}
                size="medium"
                color="success"
                sx={{
                  width: "140px",
                  marginTop: "8px",
                  backgroundColor: "#FFA3C7",
                  fontWeight: 700,
                }}
              />
            </Stack>
            <Stack
              direction="column"
              sx={{
                marginTop: "10px",
                marginRight: "10px",
                alignItems: "center",
              }}
            >
              <Chip
                label="Income"
                size="medium"
                variant="outlined"
                sx={{ width: "140px", borderColor: "#FFA3C7" }}
              />
              <Chip
                label={
                  data &&
                  getCampaignFee(
                    data?.influencerGetActiveCampaign.campaignContracts
                  )
                }
                size="medium"
                color="success"
                sx={{
                  width: "140px",
                  marginTop: "8px",
                  backgroundColor: "#FFA3C7",
                  fontWeight: 700,
                }}
              />
            </Stack>
          </Stack>
          <Grid
            item
            xs={2}
            container
            justifyContent="start"
            style={{ maxWidth: "100%" }}
          >
            <Stack
              direction="row"
              sx={{
                marginTop: "10px",
                marginRight: "10px",
                alignItems: "start",
              }}
            >
              <Stack
                direction="column"
                sx={{
                  marginTop: "10px",
                  marginRight: "10px",
                  alignItems: "center",
                }}
              >
                <Chip
                  label="No. of Followers"
                  size="medium"
                  variant="outlined"
                  sx={{
                    width: "140px",
                    marginBottom: "8px",
                    borderColor: "#FFA3C7",
                  }}
                />
                {data?.influencerGetActiveCampaign.followerPreference.map(
                  (follower) => (
                    <Chip
                      label={follower}
                      size="medium"
                      sx={{
                        marginTop: "4px",
                        backgroundColor: "#FFA3C7",
                        color: "#FFFFFF",
                        fontWeight: 700,
                      }}
                    />
                  )
                )}
              </Stack>

              <Stack
                direction="column"
                sx={{
                  marginTop: "10px",
                  marginRight: "10px",
                  alignItems: "center",
                }}
              >
                <Chip
                  label="Age Group"
                  size="medium"
                  variant="outlined"
                  sx={{
                    width: "140px",
                    marginBottom: "8px",
                    borderColor: "#FFA3C7",
                  }}
                />
                {data?.influencerGetActiveCampaign.ageGroup.map((age) => (
                  <Chip
                    label={age}
                    size="medium"
                    sx={{
                      marginTop: "4px",
                      backgroundColor: "#FFA3C7",
                      color: "#FFFFFF",
                      fontWeight: 700,
                    }}
                  />
                ))}
              </Stack>
            </Stack>
            <Stack
              direction="row"
              sx={{
                marginTop: "10px",
                marginRight: "10px",
                alignItems: "start",
              }}
            >
              <Stack
                direction="column"
                sx={{
                  marginTop: "10px",
                  marginRight: "10px",
                  alignItems: "center",
                }}
              >
                <Chip
                  label="Start Date"
                  size="medium"
                  variant="outlined"
                  sx={{
                    width: "140px",
                    marginBottom: "8px",
                    borderColor: "#FFA3C7",
                  }}
                />
                {data?.influencerGetActiveCampaign?.startDate && (
                  <Chip
                    label={formatDate(
                      data?.influencerGetActiveCampaign.startDate
                    )}
                    size="medium"
                    sx={{
                      marginTop: "4px",
                      backgroundColor: "#FFA3C7",
                      color: "#FFFFFF",
                      fontWeight: 700,
                    }}
                  />
                )}
              </Stack>

              <Stack
                direction="column"
                sx={{
                  marginTop: "10px",
                  marginRight: "10px",
                  alignItems: "center",
                }}
              >
                <Chip
                  label="End Date"
                  size="medium"
                  variant="outlined"
                  sx={{
                    width: "140px",
                    marginBottom: "8px",
                    borderColor: "#FFA3C7",
                  }}
                />
                {data?.influencerGetActiveCampaign?.endDate && (
                  <Chip
                    label={formatDate(
                      data?.influencerGetActiveCampaign.endDate
                    )}
                    size="medium"
                    sx={{
                      marginTop: "4px",
                      backgroundColor: "#FFA3C7",
                      color: "#FFFFFF",
                      fontWeight: 700,
                    }}
                  />
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CampaignDetailBanner;
