import { gql } from "@apollo/client";

const UPDATE_WISHLIST_ITEM_STATUS = gql`
  mutation updateWishlistStatus($wishlistId: ID!) {
    updateWishlistStatus(wishlistId: $wishlistId) {
      id
      brandId
      collaborated
      brand {
        id
        name
        image
        brandName
      }
    }
  }
`;

export default UPDATE_WISHLIST_ITEM_STATUS;
