import {useState} from "react";
// @mui
import {Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from "@mui/material";
// component
import {useMutation, useQuery} from "@apollo/client";
import GET_MY_TASK from "../../../graphql/queries/getMyCampaignTask";
import DELETE_TASK from "../../../graphql/mutations/deleteTask";

import Iconify from "../../../components/iconify";
import {TaskUploader} from "./TaskUploader";
import s3_config from "../../../config/s3_config";
import S3 from "aws-s3";

const S3Client = new S3(s3_config);

export default function CampaignTask({campaignId}) {
    const [open, setOpen] = useState(null);
    const [deleteTask] = useMutation(DELETE_TASK)

    const {data, refetch: influencerGetTasks} = useQuery(GET_MY_TASK, {
        variables: {
            userId: localStorage.getItem("currentUser"),
            campaignId: campaignId,
        },
    });

    function extractObjectKeyFromS3Url(url) {
        url = url.videoSubmission || url.imageSubmission
        const parts = url?.split('/');
        return parts[parts?.length - 1];
    }

    function deleteItem(submission, taskId) {
        const params = {
            taskId: taskId,
            ...submission
        }
        deleteTask({
            variables: params
        }).then((_result) => {
            influencerGetTasks();
            const objectKey = extractObjectKeyFromS3Url(submission)
            S3Client.deleteFile(objectKey, function (err, _data) {
                if (err) {
                    console.error("Error deleting object from S3:", err);
                } else {
                    console.log("Deleted object from S3:", objectKey);
                }
            });

        }).catch((error) => {
            console.error('Mutation error:', error);
        });

    }

    return (
        <Grid item xs={12} mt={2}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell align="left">Task Description</TableCell>
                            <TableCell align="center">Task Status</TableCell>
                            <TableCell align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.influencerGetTasks.map((task, index) => (
                            <TableRow
                                key={"influencer-task" + task.id}
                                sx={{"&:last-child td, &:last-child th": {border: 0}}}
                            >
                                <TableCell sx={{width: 40}}>{index}</TableCell>
                                <TableCell>{task.description}</TableCell>
                                <TableCell sx={{width: 180}} align="center">
                                    <Button
                                        variant="contained"
                                        color={
                                            task.status === 'Accepted' ? 'success' :
                                                task.status === 'Rejected' ? 'error' :
                                                    task.status === 'Submitted' ? 'warning' :
                                                        task.status === 'Pending' ? 'secondary' :
                                                            'primary'
                                        }
                                    >
                                        {task.status}
                                    </Button>
                                </TableCell>

                                <TableCell sx={{width: 180}} align="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setOpen(task.id)}
                                        disabled={['Accepted', 'Submitted'].includes(task.status)}
                                    >
                                        Submit Content
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid item xs={12} justifyContent="space-around" mt={2}>
                {data?.influencerGetTasks.map((task, index) =>
                    task.imageSubmissions.map((submission) => (
                        <div style={{position: "relative", display: "inline-block"}}>
                            <img
                                src={submission}
                                key={`image-submission-${index}`}
                                style={{width: "auto", height: "600px"}}
                                alt={`pic-submission-${index}`}
                            />
                            <Iconify icon="eva:trash-2-outline" sx={{
                                mr: 2,
                                position: "absolute",
                                top: "10px",
                                right: "-16px",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "red"
                            }}
                                     onClick={() => {
                                         deleteItem({imageSubmission: submission}, task.id)
                                     }}
                            />
                        </div>
                    ))
                )}
            </Grid>
            <Grid item xs={12} justifyContent="space-around" mt={2}>
                {data?.influencerGetTasks.map((task, _index) =>
                    task.videoSubmissions?.map((submission, index) => (
                        <div style={{position: "relative", display: "inline-block"}}>
                            <video
                                src={submission}
                                key={`video-submission-${index}`}
                                style={{width: "auto", height: "600px"}}
                                alt={`video-submission-${index}`}
                                controls
                            />
                            <Iconify icon="eva:trash-2-outline" sx={{
                                mr: 2,
                                position: "absolute",
                                top: "10px",
                                right: "-16px",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "red"
                            }}
                                     onClick={() => {
                                         deleteItem({videoSubmission: submission}, task.id)
                                     }}
                            />
                        </div>
                    ))
                )}
            </Grid>
            {!!open && <TaskUploader open={!!open} setOpen={setOpen} id={open}/>}
        </Grid>
    );
}
