// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/scroll-to-top";
import { StyledChart } from "./components/chart";

import { useState } from "react";

// ----------------------------------------------------------------------

export default function App() {
  const [loggedIn, setLoggedIn] = useState(
    !!localStorage.getItem("currentUser")
  );
  return (
    <ThemeProvider>
      <ScrollToTop />
      <StyledChart />
      <Router loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
    </ThemeProvider>
  );
}
