import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { Link as RouterLink } from "react-router-dom";
import Iconify from "../../components/iconify";
import { useMutation } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import LOGIN from "../../graphql/mutations/login";
import { Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import REQUEST_OTP from "../../graphql/mutations/requestOtp";
import RESET_PASSWORD from "../../graphql/mutations/reset_password";


// ----------------------------------------------------------------------
const useStyles = makeStyles({
  addWishBtn: {
    backgroundColor: "#EF2E83",
    margin: "1px 4px",
    height: 36,
  },
  inputField: {
    padding: 5,
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    maxWidth: "678px !important",
    height: 300,
    maxHeight: "90vh !important",
    backgroundColor: "#fff",
    border: "2px solid #fff",
    borderRadius: "24px !important",
    padding: "20px",
  },
  OtpModalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    maxWidth: "678px !important",
    height: 400,
    maxHeight: "90vh !important",
    backgroundColor: "#fff",
    border: "2px solid #fff",
    borderRadius: "24px !important",
    padding: "20px",
  },
});
// ----------------------------------------------------------------------
export default function LoginForm({ setLoggedIn, setError }) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();
  const [openResetModal, setOpenResetModal] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [forgottenAccountEmail, setForgottenAccountEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [login] = useMutation(LOGIN, {
    onCompleted: (res) => {
      if (res.userLogin2.authenticatable.accountStatus !== "Pending") {
        localStorage.setItem("currentUser", res.userLogin2.authenticatable.id);
        localStorage.setItem(
          "accessToken",
          res.userLogin2.credentials.accessToken
        );
        localStorage.setItem("client", res.userLogin2.credentials.client);
        localStorage.setItem("uid", res.userLogin2.credentials.uid);
        localStorage.setItem("name", res.userLogin2.authenticatable.name);
        localStorage.setItem("pic", res.userLogin2.authenticatable.image);
        setLoggedIn(true);
        navigate("/dashboard", { replace: true });
      } else {
        setError("Your Account is Pending Approval");
      }
    },
  });

  const handleForgetInput = (event) => {
    setForgottenAccountEmail(event.currentTarget.value);
  };

  const [generateOtp] = useMutation(REQUEST_OTP, {
    variables: {
      email: forgottenAccountEmail,
    },
    onCompleted: (res) => {
      setError(res.influencerRequestOtp);
      setOpenOtpModal(false);
    },
  });

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    variables: {
      email: forgottenAccountEmail,
      otp: otp,
      password: password,
    },
    onCompleted: (res) => {
      if (res.influencerResetPassword === "Password Reset Successfully") {
        setForgottenAccountEmail(false);
        setOtp("");
        setPassword("");
        setOpenOtpModal(false);
        setOpenResetModal(false);
        setError(res.influencerResetPassword);
      } else {
        setError(res.influencerResetPassword);
      }
    },
  });

  const handleClick = () => {
    login({
      variables: {
        email: email,
        password: password,
      },
    });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="start"
        justifyContent="space-between"
        sx={{ my: 2 }}
        >
        <Link
          href="#"
          variant="subtitle2"
          underline="hover"
          onClick={() => setOpenResetModal(true)}
          component={RouterLink}
        >
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleClick}
      >
        Login
      </LoadingButton>

      <Modal
        open={openResetModal}
        onClose={() => setOpenResetModal(false)}
        aria-labelledby="request-password-reset"
        sx={{ width: 320, margin: "auto" }}
      >
        <Grid
          item
          xs={12}
          container
          direction="column"
          className={classes.modalStyle}
          alignItems="center"
        >
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            Request Reset OTP
          </Typography>
          <TextField
            fullWidth
            variant="standard"
            id="forgotten-email"
            label="Enter Email"
            onChange={handleForgetInput}
            defaultValue=""
            className={classes.inputField}
          />
          <br />
          <Grid item xs container justifyContent="space-evenly">
            <Button
              variant="contained"
              onClick={generateOtp}
              className={classes.addWishBtn}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenResetModal(false)}
              className={classes.addWishBtn}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenOtpModal(true)}
              className={classes.addWishBtn}
            >
              Enter OTP
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={openOtpModal}
        onClose={() => {
          setOpenOtpModal(false);
          setOpenResetModal(false);
        }}
        aria-labelledby="enter-otp-modal"
        sx={{ width: 320, margin: "auto" }}
      >
        <Grid
          item
          xs={12}
          container
          direction="column"
          className={classes.OtpModalStyle}
          alignItems="center"
        >
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            Reset Password
          </Typography>
          <TextField
            variant="standard"
            id="old-email"
            label="Enter Email"
            onChange={(event) =>
              setForgottenAccountEmail(event.currentTarget.value)
            }
            defaultValue=""
            className={classes.inputField}
          />
          <br />
          <TextField
            variant="standard"
            id="received-otp"
            label="Enter OTP"
            onChange={(event) => setOtp(event.currentTarget.value)}
            defaultValue=""
            className={classes.inputField}
          />
          <br />
          <TextField
            variant="standard"
            type="password"
            id="new-password"
            label="Enter New Password"
            onChange={(event) => setPassword(event.currentTarget.value)}
            defaultValue=""
            className={classes.inputField}
          />
          <br />
          <Grid item xs container justifyContent="space-evenly">
            <Button
              variant="contained"
              onClick={resetPassword}
              className={classes.addWishBtn}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setOpenOtpModal(false);
                setOpenResetModal(false);
              }}
              className={classes.addWishBtn}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}
