import { gql } from "@apollo/client";

const GET_CAMPAIGN_DETAILS = gql`
  query getCampaignDetails($campaignId: ID!) {
    getCampaignDetails(campaignId: $campaignId) {
      id
      ageGroup
      followerPreference
      campaignType
      category {
        id
        name
      }
      coverPic
      description
      femalePercentage
      malePercentage
      media
      name
      status
      contract
      commissionModel
    }
  }
`;

export default GET_CAMPAIGN_DETAILS;
