import { useState } from "react";
// @mui
import { Menu, Button, MenuItem, Typography } from "@mui/material";
// component
import Iconify from "../../../components/iconify";
import HowToVideoPopup from "../../../components/HowToVideoPopup";

// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
  { value: "New", label: "New" },
  { value: "Old", label: "Old" },
  { value: "Invited", label: "Invited" },
  { value: "Interested", label: "Interested" },
  { value: "Active", label: "Active" },
];

export default function CampaignSort({ setActiveFilter }) {
  const [open, setOpen] = useState(null);
  const [selected, setSelected] = useState("New");
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleSelection = (value) => {
    setSelected(value);
    setActiveFilter(value);
    setOpen(null);
  };

  return (
    <>
    { selected == 'Active' && <HowToVideoPopup 
      title="How to upload your content to brands" 
      description="Watch the video below to learn how to upload your content for brand approval." 
      youtubeId="4nF3KXpE6rE"
      />
    }

      <Button
        color="inherit"
        disableRipple
        onClick={handleOpen}
        endIcon={
          <Iconify
            icon={open ? "eva:chevron-up-fill" : "eva:chevron-down-fill"}
          />
        }
      >
        Sort By:&nbsp;
        <Typography
          component="span"
          variant="subtitle2"
          sx={{ color: "text.secondary" }}
        >
          {selected}
        </Typography>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {SORT_BY_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === "All"}
            onClick={() => handleSelection(option.value)}
            sx={{ typography: "body2" }}
          >
            {option.value}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
