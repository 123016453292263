import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet-async";
import {styled} from "@mui/material/styles";
import {
    ToggleButtonGroup,
    ToggleButton,
    Typography,
    Container,
    Button,
    Grid,
} from "@mui/material";
import {useMutation, useQuery} from "@apollo/client";
import GET_SURVEYS from "../graphql/queries/getSurveys";
import ANSWER_SURVEY from "../graphql/mutations/answerSurvey";
import GET_ANSWERED_SURVEYS from "../graphql/queries/getAnsweredSurveys";
import {makeStyles} from "@mui/styles";
import DELETE_ANSWER from "../graphql/mutations/deleteAnswer";
import RESTORE_ANSWER from "../graphql/mutations/restoreAnswer";
import {Select, MenuItem, Box} from "@mui/material";

const StyledContent = styled("div")(({theme}) => ({
    width: "100%",
    margin: "auto",
    display: "flex",
    textAlign: "center",
    justifyContent: "space-around",
    flexDirection: "row",
}));

const useStyles = makeStyles({
    surveys: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: "10px !important",
        justifyContent: "space-between",
        alignItems: "start",
        width: "90%",
        margin: "auto",
    },
});

export default function CreatorSurvey() {
    const classes = useStyles();
    const [sortOrder, setSortOrder] = useState("newest");
    const [sortAnsweredOrder, setSortAnsweredOrder] = useState("newest");

    const [respond] = useMutation(ANSWER_SURVEY, {
        onCompleted: () => {
            refetchSurveys();
            refetchAnsweredSurveys();
        },
    });

    const handleChange = (newAnswer, id) => {
        respond({
            variables: {
                userId: localStorage.getItem("currentUser"),
                surveyId: id,
                answer: newAnswer,
            },
        });
    };

    const {data: surveys, refetch: refetchSurveys} = useQuery(GET_SURVEYS, {
        variables: {
            userId: localStorage.getItem("currentUser"),
        },
    });

    const {data: answeredSurveys, refetch: refetchAnsweredSurveys} = useQuery(
        GET_ANSWERED_SURVEYS,
        {
            variables: {
                userId: localStorage.getItem("currentUser"),
            },
        }
    );

    const [deleteAnswer] = useMutation(DELETE_ANSWER, {
        onCompleted: () => {
            refetchSurveys();
            refetchAnsweredSurveys();
        },
    });

    const [restoreAnswer] = useMutation(RESTORE_ANSWER, {
        onCompleted: () => {
            refetchSurveys();
            refetchAnsweredSurveys();
        },
    });

    const handleSortOrderChange = (order) => {
        setSortOrder(order);
    };

    const handleAnsweredSortOrderChange = (order) => {
        setSortAnsweredOrder(order);
    };

    const sortedSurveys = surveys?.getSurvey
        ? [...surveys.getSurvey].sort((a, b) => {
            if (sortOrder === "newest") {
                return (
                    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                );
            } else {
                return (
                    new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
                );
            }
        })
        : [];

    const sortedAnsweredSurveys = answeredSurveys?.getAnsweredSurvey
        ? [...answeredSurveys.getAnsweredSurvey].sort((a, b) => {
            if (sortAnsweredOrder === "newest") {
                return (
                    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                );
            } else {
                return (
                    new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
                );
            }
        })
        : [];

    return (
        <>
            <Helmet>
                <title> Creator Surveys | Authentic Influencers </title>
            </Helmet>

            <Container>
                <Typography
                    variant="h3"
                    sx={{
                        width: "100%",
                        borderTop: 1,
                        borderBottom: 1,
                        backgroundColor: "#FFA3C7",
                        color: "#FFF",
                    }}
                    align="center"
                    mb={3}
                >
                    Creator Surveys
                </Typography>
                <Box display="flex" justifyContent="flex-end">
                    <Select
                        value={sortOrder}
                        sx={{fontSize: "15px", marginBottom: "10px"}}
                        onChange={(event) => handleSortOrderChange(event.target.value)}
                    >
                        <MenuItem value={"newest"} sx={{fontSize: "15px"}}>
                            Newest
                        </MenuItem>
                        <MenuItem value={"oldest"} sx={{fontSize: "15px"}}>
                            Oldest
                        </MenuItem>
                    </Select>
                </Box>
                <StyledContent className={classes.surveys}>
                    {sortedSurveys.map((survey) => (
                        <Grid
                            item
                            xs={12}
                            key={`survey-#-${survey?.id}`}
                        >
                            <img
                                style={{objectFit: "contain"}}
                                src={survey?.coverImage}
                                alt={survey?.question}
                                width={300}
                                height={400}
                            />
                            <Grid item xs={12} mb={4}>
                                <Typography variant="h5" mt={2} mb={2}>
                                    {survey?.question}
                                </Typography>
                                <ToggleButtonGroup
                                    color="primary"
                                    exclusive
                                    onChange={(e) => handleChange(e.target.value, survey.id)}
                                    aria-label="Survey"
                                >
                                    {survey?.answers?.filter(answer => answer !== "").map((answer, index) => (
                                        <ToggleButton
                                            value={answer}
                                            key={`survey-${survey?.id}-asnwer-${index}`}
                                            sx={{
                                                "&:hover": {
                                                    backgroundColor: "#FFA3C7",
                                                    color: "#FFF",
                                                },
                                            }}
                                        >
                                            {answer}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                    ))}
                </StyledContent>
                <Typography
                    variant="h4"
                    sx={{
                        width: "100%",
                        borderTop: 1,
                        borderBottom: 1,
                        backgroundColor: "#FFA3C7",
                        color: "#FFF",
                    }}
                    align="center"
                    mt={3}
                    mb={3}
                >
                    Answered Surveys
                </Typography>
                <Box display="flex" justifyContent="flex-end">
                    <Select
                        value={sortOrder}
                        sx={{fontSize: "15px", marginBottom: "10px"}}
                        onChange={(event) => handleAnsweredSortOrderChange(event.target.value)}
                    >
                        <MenuItem value={"newest"} sx={{fontSize: "15px"}}>
                            Newest
                        </MenuItem>
                        <MenuItem value={"oldest"} sx={{fontSize: "15px"}}>
                            Oldest
                        </MenuItem>
                    </Select>
                </Box>
                <StyledContent className={classes.surveys}>
                    {sortedAnsweredSurveys.map((survey) => (
                        <Grid
                            item
                            xs={12}
                            key={`survey-#-${survey.id}`}
                            sx={{marginTop: 5}}
                        >
                            <img
                                style={{objectFit: "contain"}}
                                src={survey?.creatorSurvey?.coverImage}
                                alt={survey?.creatorSurvey?.question}
                                width={300}
                                height={400}
                            />
                            <Grid item xs={12}>
                                <Typography variant="h5" mt={2} mb={2}>
                                    {survey?.creatorSurvey?.question}
                                </Typography>

                                <Button
                                    variant="contained"
                                    color="warning"
                                    style={{marginBottom: "15px"}}
                                    onClick={() =>
                                        survey.deletedAt
                                            ? restoreAnswer({
                                                variables: {
                                                    surveyAnswerId: survey.id,
                                                },
                                            })
                                            : deleteAnswer({
                                                variables: {
                                                    surveyAnswerId: survey.id,
                                                },
                                            })
                                    }
                                >
                                    {survey.deletedAt ? "Restore" : "Delete"}
                                </Button>

                                <ToggleButtonGroup
                                    color="primary"
                                    exclusive
                                    aria-label="Survey"
                                    style={{display: "flow-root"}}
                                >
                                    {survey?.creatorSurvey?.answers.filter(answer => answer !== "").map((answer, index) => (
                                        <ToggleButton
                                            value={survey?.answer}
                                            key={`survey-${survey?.id}-answer-${index}`}
                                            sx={{
                                                backgroundColor:
                                                    survey.answer === answer ? "#FFA3C7" : "",
                                                color: survey.answer === answer ? "#FFF" : "",
                                                "&:hover": {
                                                    backgroundColor: "#FFA3C7",
                                                    color: "#FFF",
                                                },
                                            }}
                                            disabled={survey?.answer !== answer}
                                        >
                                            {answer}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                    ))}
                </StyledContent>
            </Container>
        </>
    );
}
