import { gql } from "@apollo/client";

const RESET_PASSWORD = gql`
  mutation influencerResetPassword(
    $email: String!
    $otp: String!
    $password: String!
  ) {
    influencerResetPassword(email: $email, otp: $otp, password: $password)
  }
`;

export default RESET_PASSWORD;
