import { gql } from "@apollo/client";

const GET_ANSWERED_SURVEYS = gql`
  query getAnsweredSurvey($userId: ID!) {
    getAnsweredSurvey(userId: $userId) {
      id
      answer
      deletedAt
      createdAt
      creatorSurvey {
        id
        coverImage
        question
        answers
      }
    }
  }
`;

export default GET_ANSWERED_SURVEYS;
