import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
//
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";
import './App.css'

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById("root"));
const client = new ApolloClient({
  uri: "https://authenticusers.co/graphql",
  // uri: "http://localhost:3000/graphql",
  // uri: "https://shrouded-dusk-40789-82873da8bdd3.herokuapp.com/graphql",
  
  headers: {
      accessToken: localStorage.getItem("accessToken") || "",
    client: localStorage.getItem("client") || "",
    uid: localStorage.getItem("uid") || "",
  },
  cache: new InMemoryCache(),
});
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </BrowserRouter>
  </HelmetProvider>
);

serviceWorker.unregister();

reportWebVitals();
