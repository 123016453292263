import * as React from "react";
import { Button } from "@mui/material";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const DownloadContract = ({campaign}) => {
  const fetchFileContent = async (url) => {
    const response = await fetch(url);
    const content = await response.text();
    return content;
};

const downloadAsPDF = async (htmlContent, filename) => {
  // Create a temporary element to hold the HTML
  const tempElement = document.createElement('div');
  tempElement.style.position = 'absolute';
  tempElement.style.left = '-9999px'; // Move element off-screen
  tempElement.innerHTML = htmlContent;
  document.body.appendChild(tempElement); // Append to the document body

  // Extract the text content
  const textContent = tempElement.innerText || tempElement.textContent;

  // Create a new jsPDF instance
  const pdf = new jsPDF();

  // Dimensions
  const pageHeight = pdf.internal.pageSize.height;
  const pageWidth = pdf.internal.pageSize.width;

  // Top & bottom margin; Modify as needed
  const margin = 10;
  const maxLineWidth = pageWidth - 2 * margin;

  // Split the text content by lines for better formatting
  const textLines = pdf.splitTextToSize(textContent, maxLineWidth);
  let y = margin;

  // Add text content to the PDF and handle pagination
  textLines.forEach((line) => {
    if (y + 10 > pageHeight - margin) {  // If reaches bottom margin
      pdf.addPage();
      y = margin;
    }
    pdf.text(margin, y, line);
    y += 10;  // Line height; Modify as needed
  });

  // Save the PDF with the given filename
  pdf.save(filename);

  // Clean up by removing the temporary element
  document.body.removeChild(tempElement);
};
  function getInfluencerContract(contracts) {
    let contract = null; 

    contracts.some((item) => {
      if (item.userId.toString() === localStorage.getItem("currentUser").toString()) {
        contract = item.customContract;
        return true; // Break the loop
      }
      return false;
    });

    return contract;
  }


  const handleDownload = async () =>{
    let combinedContent = await fetchFileContent( "https://authentic-pps.s3.eu-west-2.amazonaws.com/authentic_default_contract.txt");
    if(campaign.influencerGetActiveCampaign?.contract){
      const content = await fetchFileContent(campaign.influencerGetActiveCampaign?.contract);
      combinedContent += `<h1>Brand Additional Clause</h1><div>${content}</div><br/>`
    }
    const influ_contract = getInfluencerContract(campaign.influencerGetActiveCampaign.campaignContracts)
    debugger
    if(influ_contract){
      const content = await fetchFileContent(influ_contract);
      combinedContent += `<h1>Influencer Additional Clause</h1><div>${content}</div><br/>`
    }
    downloadAsPDF(combinedContent, 'download_contract.pdf');
  } 

  return (
    <Button variant="contained" style={{marginLeft: "15px"}} onClick={handleDownload}>
      Download Contract
    </Button>
  );
};

export default DownloadContract;
