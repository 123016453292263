import { gql } from "@apollo/client";

const GET_WISHLIST = gql`
  query getInfluencerWishlist($influencerId: ID!) {
    getInfluencerWishlist(influencerId: $influencerId) {
      id
      brandId
      collaborated
      brandName
      brand {
        id
        name
        image
        brandName
      }
    }
  }
`;

export default GET_WISHLIST;
