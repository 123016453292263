import { gql } from '@apollo/client';


const GET_CAMPAIGN_CATEGORIES = gql`
  query getCampaignCategories {
    getCampaignCategories {
      id
      name
    }
  }`

  export default GET_CAMPAIGN_CATEGORIES;