import React, { useState, useRef } from "react";
import {
  Avatar,
  Badge,
  Grid,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import TIKTOK_ICON from "../../../components/logo/Tiktok.png";
import INSTAGRAM_ICON from "../../../components/logo/Instagram.png";
import { useQuery, useMutation } from "@apollo/client";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import EditProfile from "./editProfileModal";
import { ProfilePicUploader } from "./profilePictureUploader";
import { CircularLoader } from "../../../components/loaders";
import SignatureCanvas from "react-signature-canvas";
import GET_BRAND from "../../../graphql/queries/getBrandInfo";
import UPDATE_USER_INFO from "../../../graphql/mutations/updateUserInfo";
import useStyles from "./styles";

const UserBanner = ({ user }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openPp, setOpenPp] = useState(false);
  const [profileInfo, setProfileInfo] = useState(null);
  const [signature, setSignature] = useState(null); // State for the influencer signature
  const [isAddingSignature, setIsAddingSignature] = useState(false);
  const sigCanvasRef = useRef({});

  const { loading, error, data } = useQuery(GET_BRAND, {
    variables: {
      userId: user,
    },
    onCompleted: (res) => {
      setProfileInfo({
        name: res.getBrand.name,
        image: res.getBrand.image,
        email: res.getBrand.email,
        country: res.getBrand.country,
        city: res.getBrand.city,
        instagram: res.getBrand.instagram,
        tiktok: res.getBrand.tiktok,
        facebook: res.getBrand.facebook,
        theme: res.getBrand.theme,
        website: res.getBrand.website,
        youtube: res.getBrand.youtube,
        category: res.getBrand.categories.map((cat) => ({
          id: cat.id,
          name: cat.name,
        })),
        signature: res.getBrand.signature, // Retrieve the influencer signature
      });

      // Set influencer signature if available
      if (res.getBrand.signature) {
        setSignature(res.getBrand.signature);
      }
    },
  });

  const [updateUserInfo] = useMutation(UPDATE_USER_INFO);

  if (loading || !profileInfo) return <CircularLoader title="Profile" />;
  if (error) {
    console.error("Error fetching user profile:", error);
    return (
      <Typography variant="body1">Error fetching user profile.</Typography>
    );
  }

  const saveSignature = () => {
    if (sigCanvasRef.current.isEmpty()) {
      alert("Please provide a signature first.");
    } else {
      const signatureImage = sigCanvasRef.current.toDataURL();
      setSignature(signatureImage); // Update local state with the new signature
      setIsAddingSignature(false);

      // Update user info with the influencer signature
      updateUserInfo({
        variables: {
          userId: localStorage.getItem("currentUser"),
          email: profileInfo.email,
          name: profileInfo.name,
          image: profileInfo.image,
          instagram: profileInfo.instagram,
          tiktok: profileInfo.tiktok,
          facebook: profileInfo.facebook,
          youtube: profileInfo.youtube,
          website: profileInfo.website,
          country: profileInfo.country,
          city: profileInfo.city,
          theme: profileInfo.theme,
          categories: profileInfo.category.map((category) => category.id),
          signature: signatureImage || null, // Pass the signature image to the mutation
        },
      })
        .then(() => {
          // Optional: Add logic for success handling if needed
        })
        .catch((error) => {
          console.error("Error updating user info:", error);
          // Optional: Add error handling logic
        });
    }
  };

  const clearSignature = () => {
    sigCanvasRef.current.clear();
  };

  const startAddingSignature = () => {
    setIsAddingSignature(true);
  };

  const editSignature = () => {
    setSignature(null); // Clear the current signature
    setIsAddingSignature(true);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        container
        className={classes.banner}
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Badge
          onClick={() => setOpenPp(true)}
          id="edit-profile-icon"
          overlap="circular"
          badgeContent={
            <CameraAltIcon
              sx={{
                backgroundColor: "#FFFFFF",
                color: "#EE2F82",
                borderRadius: 10,
                fontSize: "1.8rem",
                padding: 0.2,
              }}
            />
          }
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={{
            "& :hover": {
              cursor: "pointer",
            },
          }}
        >
          <Avatar
            id="avatar"
            alt={profileInfo.name}
            src={profileInfo.image}
            className={classes.avatar}
          />
        </Badge>
        <Typography variant="h4" color="inherit">
          {profileInfo.name}
        </Typography>

        <Typography variant="subtitle1" color="inherit">
          {profileInfo.email}
        </Typography>
        {(profileInfo.instagram || profileInfo.tiktok) && (
          <Grid item xs style={{ maxHeight: 50 }}>
            {profileInfo.instagram && (
              <IconButton
                aria-label="instagram"
                onClick={() =>
                  window.open(
                    `https://www.instagram.com/${profileInfo.instagram}`,
                    "_blank"
                  )
                }
                className={classes.socialIcon}
              >
                <Avatar src={INSTAGRAM_ICON} />
              </IconButton>
            )}
            {profileInfo.tiktok && (
              <IconButton
                aria-label="tiktok"
                onClick={() =>
                  window.open(
                    `https://www.tiktok.com/@${profileInfo.tiktok}`,
                    "_blank"
                  )
                }
                className={classes.socialIcon}
              >
                <Avatar src={TIKTOK_ICON} />
              </IconButton>
            )}
          </Grid>
        )}
        <IconButton
          variant="contained"
          className={classes.editProfileBtn}
          onClick={() => setOpen(true)}
        >
          <CreateIcon />
        </IconButton>
        {open && <EditProfile storedProfileInfo={profileInfo} open={open} setOpen={setOpen} />}
        {openPp && (
          <ProfilePicUploader
            open={openPp}
            setOpen={setOpenPp}
            currentPicture={profileInfo.image || ""}
          />
        )}
      </Grid>

      {/* Display Influencer Signature or Initial Icon */}
      {signature ? (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <img
              src={signature}
              alt="Influencer Signature"
              style={{ maxWidth: 200 }}
            />
          </Grid>
          <Grid item>
            <IconButton
              variant="contained"
              className={classes.editSignatureBtn}
              onClick={() => editSignature()}
            >
              <CreateIcon />
            </IconButton>
          </Grid>
        </Grid>
      ) : (
        !isAddingSignature && (
          <Button variant="contained" onClick={() => startAddingSignature()}>
            Add Signature
          </Button>
        )
      )}
      <IconButton
        variant="contained"
        className={classes.editProfileBtn}
        onClick={() => setOpen(true)}
      >
        <CreateIcon />
      </IconButton>
      {open && (
        <EditProfile
          storedProfileInfo={profileInfo}
          open={open}
          setOpen={setOpen}
        />
      )}
      {openPp && (
        <ProfilePicUploader
          open={openPp}
          setOpen={setOpenPp}
          currentPicture={profileInfo.image || ""}
        />
      )}

      {/* Signature Canvas */}
      {isAddingSignature && !signature && (
        <div>
          <SignatureCanvas
            penColor="black"
            canvasProps={{
              width: 300,
              height: 150,
              className: "sigCanvas",
              style: { backgroundColor: "#f0f0f0" },
            }}
            ref={sigCanvasRef}
          />

          {/* Controls */}
          <Grid container spacing={2} marginTop="-50px" justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => saveSignature()}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => clearSignature()}>
                Clear
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default UserBanner;
