import { gql } from "@apollo/client";

const SIGNUP = gql`
  mutation userRegister(
    $email: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    userRegister(
      email: $email
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      authenticatable {
        id
        accountStatus
      }
    }
  }
`;

export default SIGNUP;
