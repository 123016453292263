function applyKernel(data, kernel, width, height) {
  const side = Math.round(Math.sqrt(kernel.length));
  const halfSide = Math.floor(side / 2);
  const resultData = new Uint8ClampedArray(data.length);
  
  const kernelOffsets = [];
  for (let ky = 0; ky < side; ky++) {
    for (let kx = 0; kx < side; kx++) {
      kernelOffsets.push((ky - halfSide) * width + (kx - halfSide));
    }
  }

  for (let y = halfSide; y < height - halfSide; y++) {
    for (let x = halfSide; x < width - halfSide; x++) {
      let sum = 0;
      const offset = (y * width + x) * 4;
      
      for (let i = 0; i < kernel.length; i++) {
        const pixelOffset = (offset / 4 + kernelOffsets[i]) * 4;
        sum += data[pixelOffset] * kernel[i]; // Using the red channel for grayscale calculation
      }

      const clampedSum = Math.min(Math.max(sum, 0), 255);
      resultData[offset] = resultData[offset + 1] = resultData[offset + 2] = clampedSum;
      resultData[offset + 3] = data[offset + 3]; // Preserve alpha channel
    }
  }
  
  return resultData;
}
function calculateVariance(data) {
  const mean = data.reduce((sum, value) => sum + value, 0) / data.length;
  let variance = 0;
  for (let i = 0; i < data.length; i++) {
    variance += (data[i] - mean) ** 2;
  }
  return variance / data.length;
}



const imageQuality = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      resolve(false);
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const minResolution = { width: 500, height: 800 }; // Minimum resolution
      resolve(img.width >= minResolution.width && img.height >= minResolution.height)
    //   const canvas = document.createElement('canvas');
    //   const ctx = canvas.getContext('2d');
    //   canvas.width = img.width;
    //   canvas.height = img.height;
    //   ctx.drawImage(img, 0, 0, img.width, img.height);

    //   const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    //   const data = imageData.data;

    //   let totalIntensity = 0;
    //   for (let i = 0; i < data.length; i += 4) {
    //     const intensity = (data[i] + data[i + 1] + data[i + 2]) / 3;
    //     totalIntensity += intensity;
    //   }

    //   const avgIntensity = totalIntensity / (data.length / 4);

    //   const minIntensity = 50;
    //   const maxIntensity = 200;
      
    //   resolve(avgIntensity >= minIntensity && avgIntensity <= maxIntensity);
    };

    img.onerror = () => {
      reject(new Error('Failed to load image'));
    };
  });
}

export { imageQuality };


