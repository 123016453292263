import { gql } from "@apollo/client";

const GET_CHATROOM_MESSAGES = gql`
  query getChatroomMessages($userId: ID!, $chatroomId: ID!) {
    getChatroomMessages(userId: $userId, chatroomId: $chatroomId) {
      id
      text
      attachment
      sender
      createdAt
    }
  }
`;

export default GET_CHATROOM_MESSAGES;
