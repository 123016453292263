import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Card, Box, Grid, Typography, CardContent } from "@mui/material";
// utils
import Iconify from "../../../components/iconify";
import { fDate } from "../../../utils/formatTime";
import s3_config from "../../../config/s3_config";
import S3 from "aws-s3";
import DELETE_ITEM from "../../../graphql/mutations/deleteInfluencerGalleryItem";
import { useMutation } from '@apollo/client';

const S3Client = new S3(s3_config);

// ----------------------------------------------------------------------

const StyledCardMedia = styled("div")({
  position: "relative",
  paddingTop: "calc(100% * 3 / 4)",
  height: 900,
});

const StyledImage = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});
const StyledVideo = styled("video")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

// ----------------------------------------------------------------------

BlogPostCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function BlogPostCard({ post, index }) {
  const { item, createdAt } = post;
  function is_it_an_image(url) {
    const fileType = url.split(/[#?]/)[0].split(".").pop().trim();

    return fileType === "jpg" || fileType === "jpeg" || fileType === "png";
  }

  function extractObjectKeyFromS3Url(url) {
    const parts = url?.split('/');
    const objectKey = parts[parts?.length - 1];
     return objectKey;
  }

  const objectKey = extractObjectKeyFromS3Url(item)
  const [deleteItem, { loading, error }] = useMutation(DELETE_ITEM, {
      variables: { itemId: post.id },
      refetchQueries: ["getInfluencerGallery"],
      onCompleted: () => {
        S3Client.deleteFile(objectKey, function (err, data) {
          if (err) {
            console.error("Error deleting object from S3:", err);
          } else {
            console.log("Deleted object from S3:", objectKey);
          }
        });
      },
    });

  return (
    <Grid item xs={12} sm={6}>
      <Card sx={{ position: "relative" }}>
        <StyledCardMedia>
          {is_it_an_image(item) ? (
            <StyledImage alt={`gallery-post-${index}`} src={item} />
          ) : (
            item && <StyledVideo src={item} type="video/mp4" controls loop />
          )}
        </StyledCardMedia>

        <CardContent sx={{ pt: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              gutterBottom
              variant="caption"
              sx={{ color: 'text.disabled', display: 'block' }}
            >
              {fDate(createdAt)}
            </Typography>
            <Iconify icon="eva:trash-2-outline" sx={{ mr: 2, color: 'text.disabled' }} onClick={deleteItem}/>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}
