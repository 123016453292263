import { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Typography,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import S3 from "aws-s3";
import { useMutation } from "@apollo/client";
import "react-advanced-cropper/dist/style.css";
import Influencer_Campaign_Result_Image_Analysis_Result from "../../../graphql/mutations/influencerCampaignResultImageAnalysisResult";
import s3_config from "../../../config/s3_config";
import { useNavigate } from "react-router-dom";
window.Buffer = window.Buffer || require("buffer").Buffer;
const S3Client = new S3(s3_config);

export const CampaignResultsOcrUploader = ({
  open,
  setOpen,
  setTiktokPictureUrls,
  setImageGallery,
  campaignMedia,
  campaignType,
  setFields,
}) => {
  const nav = useNavigate();
  const [disableActions, setDisableActions] = useState(true);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [pictures, setPictures] = useState([]);

  useEffect(() => {
    if (pictures.length > 0) {
      setDisableActions(false);
    }
  }, [pictures]);

  const [influencerImageAnalysisResult] = useMutation(
    Influencer_Campaign_Result_Image_Analysis_Result,
    {
      variables: {
        images: pictures,
        media: campaignMedia,
        campaignResultType: campaignType,
      },
      onCompleted: (data) => {
        setFields(
          data.influencerCampaignResultImageAnalysisResult?.stats || []
        );
        handleClose();
        nav("/campaign-results");
      },
    }
  );

  const handleSave = async () => {
    setTiktokPictureUrls(pictures);
    setUploading(true);
    influencerImageAnalysisResult();
  };

  const handleClose = () => {
    setPictures("");
    setDisableActions(true);
    setUploading(false);
    setOpen(false);
    setImageGallery(true);
  };

  const handleImageUpload = async (files) => {
    setUploading(true);
    const promises = Array.from(files).map((file) => S3Client.uploadFile(file));
    Promise.all(promises)
      .then((data) => {
        setPictures(data.map((item) => item.location));
        setUploading(false);
        setDisableActions(false);
      })
      .catch((err) => {});
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      {uploading ? (
        <Grid
          item
          xs
          container
          sx={{ padding: 5 }}
          justifyContent="center"
          align="center"
          direction="column"
        >
          <CircularProgress title="Uploading" sx={{ margin: "auto" }} />
          <Typography variant="subtitle1" color="primary">
            Uploading
          </Typography>
        </Grid>
      ) : (
        <>
          <DialogTitle align="center">Upload Profile Screenshot</DialogTitle>
          {pictures.length > 0 && (
            <DialogContent>
              <ImageGallery
                items={pictures.map((picture) => ({
                  original: picture,
                  thumbnail: picture,
                }))}
                showNav={true}
                showPlayButton={false}
                showFullscreenButton={false}
                startIndex={selectedImageIndex}
                onSlide={(index) => setSelectedImageIndex(index)}
              />
            </DialogContent>
          )}
          <DialogActions>
            <LoadingButton
              variant="contained"
              component="label"
              sx={{ marginRight: 4 }}
              disabled={!disableActions}
            >
              Select Image
              <input
                multiple
                hidden
                accept="image/*"
                type="file"
                onChange={(e) => handleImageUpload(e.target.files)}
              />
            </LoadingButton>
            <Button onClick={handleSave} disabled={disableActions}>
              Upload
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
