import { useState } from "react";
// @mui
import {
  Button,
  Grid,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
// component
import { useQuery, useMutation } from "@apollo/client";
import GET_MY_TASK from "../../../graphql/queries/getMyCampaignTask";
import DELETE_TASK_SUBMISSION from "../../../graphql/mutations/deleteTaskSubmission";
import RESTORE_TASK_SUBMISSION from "../../../graphql/mutations/restoreTaskSubmission";
import { formatDate } from "../../../components/dateFormatter";
import { TaskUploader } from "./TaskUploader";

// ----------------------------------------------------------------------

export default function CampaignTaskDetails({ campaignId }) {
  const [open, setOpen] = useState(null);

  const { data, refetch: influencerGetTasks } = useQuery(GET_MY_TASK, {
    variables: {
      userId: localStorage.getItem("currentUser"),
      campaignId: campaignId,
    },
  });
  
  const [deleteTaskSubmission] = useMutation(DELETE_TASK_SUBMISSION, {
    onCompleted: () => {
      influencerGetTasks();
    },
  });

  const [restoreTaskSubmission] = useMutation(RESTORE_TASK_SUBMISSION, {
    onCompleted: () => {
      influencerGetTasks();
    },
  });

  return (
    <Grid item xs={12} mt={2}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell align="left">Task Description</TableCell>
              <TableCell align="left">Task Deadline</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.influencerGetTasks.map((task, index) => (
              <TableRow
                key={"influencer-task" + task.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={{ width: 40 }}>{index}</TableCell>
                <TableCell>{task.description}</TableCell>
                <TableCell>{formatDate(task.dueDate)}</TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12} container justifyContent="space-around" mt={2}>
        {data?.influencerGetTasks.map((task, index) =>
          task.imageSubmissions.map((submission) => (
            <img
              src={submission}
              key={`image-submission-${index}`}
              style={{ width: "auto", height: "500px" }}
              alt={`pic-submission-${index}`}
            />
          ))
        )}
      </Grid>
      <Grid item xs={12} container justifyContent="space-around" mt={2}>
        {data?.influencerGetTasks.map((task, index) =>
          task.videoSubmissions?.map((submission, index) => (
            <video
              src={submission}
              key={`video-submission-${index}`}
              style={{ width: "auto", height: "500px" }}
              alt={`video-submission-${index}`}
              controls
            />
          ))
        )}
      </Grid>
      {!!open && <TaskUploader open={!!open} setOpen={setOpen} id={open} />}
    </Grid>
  );
}
