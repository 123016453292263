import { gql } from "@apollo/client";

const Influencer_Campaign_Result_Image_Analysis_Result = gql`
  mutation influencerCampaignResultImageAnalysisResult(
    $images: [String!]!
    $campaignResultType: String!
    $media: String!
  ) {
    influencerCampaignResultImageAnalysisResult(
      images: $images
      campaignResultType: $campaignResultType
      media: $media
    ) {
      stats
    }
  }
`;

export default Influencer_Campaign_Result_Image_Analysis_Result;
