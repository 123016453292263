import { Helmet } from "react-helmet-async";
// @mui
import { Container, Stack, Typography } from "@mui/material";
// mock
import { CampaignList, CampaignSort } from "../sections/@dashboard/campaigns";
import { useLazyQuery } from "@apollo/client";
import GET_CAMPAIGNS from "../graphql/queries/getCampaignsForInfluencer";
import { useEffect, useState } from "react";
import GET_CANDIDATE_CAMPAIGNS from "../graphql/queries/getCandidateCampaignsForInfluencer";
import GET_INTERESTED_CAMPAIGNS from "../graphql/queries/getInterestedCampaignsForInfluencer";
import GET_INVITED_CAMPAIGNS from "../graphql/queries/getInvitedCampaignsForInfluencer";
import GET_INFLUENCER_FOR_POPUP from "../graphql/queries/getInfluencerForPopup";


// ----------------------------------------------------------------------

export default function CampaignsPage() {
  const [activeFilter, setActiveFilter] = useState("New");
  const [data, setData] = useState();
  const [influencer, setInfluencer] = useState();


  const [fetchActive] = useLazyQuery(GET_CANDIDATE_CAMPAIGNS, {
    fetchPolicy: "network-only",
    variables: {
      userId: localStorage.getItem("currentUser"),
    },
    onCompleted: (res) => {
      setData(res.getCandidateCampaignsForInfluencer);
    },
  });
  const [fetchInterested] = useLazyQuery(GET_INTERESTED_CAMPAIGNS, {
    fetchPolicy: "network-only",
    variables: {
      userId: localStorage.getItem("currentUser"),
    },
    onCompleted: (res) => {
      setData(res.getInterestedCampaignsForInfluencer);
    },
  });
  const [fetchInvited] = useLazyQuery(GET_INVITED_CAMPAIGNS, {
    fetchPolicy: "network-only",
    variables: {
      userId: localStorage.getItem("currentUser"),
    },
    onCompleted: (res) => {
      setData(res.getInvitedCampaignsForInfluencer);
    },
  });
  const [fetchCampaigns] = useLazyQuery(GET_CAMPAIGNS, {
    fetchPolicy: "network-only",
    variables: {
      userId: localStorage.getItem("currentUser"),
    },
    onCompleted: (res) => {
      const newData = res.getCampaignsForInfluencer
      setData(newData)
      sortData('new', newData)
    },
  });

  const [getInfluencer] = useLazyQuery(GET_INFLUENCER_FOR_POPUP, {
    fetchPolicy: "network-only",
    variables: {
      userId: localStorage.getItem("currentUser"),
    },
    onCompleted: (res) => {
      setInfluencer(res.getInfluencer);
    },
  });

  const sortData = (value, data) => {
    const sortedData =  [...data].sort((a, b) => {
      if (value === "new") {
        return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      } else {
        return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      }
      });
    setData(sortedData)
  };


  useEffect(() => {
    switch (activeFilter) {
      case "Old":
        sortData('old', data)
        getInfluencer();
        break;
      case "Interested":
        fetchInterested();
        getInfluencer();
        break;
      case "Invited":
        fetchInvited();
        getInfluencer();
        break;
      case "Active":
        fetchActive();
        getInfluencer();
        break;
      default:
        fetchCampaigns();
        getInfluencer();
        break;
    }
    //eslint-disable-next-line
  }, [activeFilter]);

  useEffect(() => {}, [fetchInterested, fetchInvited, fetchCampaigns]);
  return (
    <>
      <Helmet>
        <title> Campaigns </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Campaigns
        </Typography>

        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <CampaignSort setActiveFilter={setActiveFilter} />
          </Stack>
        </Stack>

        {data && <CampaignList campaigns={data} activeFilter={activeFilter} influencer={influencer}/>}
      </Container>
    </>
  );
}
