import { gql } from "@apollo/client";

const GET_INFLUENCER_CHATROOMS = gql`
  query influencerGetChatrooms($userId: ID!) {
    influencerGetChatrooms(userId: $userId) {
      id
      campaignId
      userId
      participiantId
      lastMessage {
        text
        createdAt
      }
      campaign {
        name
        campaignType
      }
      brand {
        id
        name
        image
      }
    }
  }
`;

export default GET_INFLUENCER_CHATROOMS;
