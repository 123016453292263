import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";

import GalleryPage from "./pages/GalleryPage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import Page404 from "./pages/Page404";
import DashboardAppPage from "./pages/DashboardAppPage";
import CampaignsPage from "./pages/CampaignsPage";
import CampaignDetailsPage from "./pages/CampaignDetailPage";
import CampaignDetailsPageWithTask from "./pages/CampaignDetailPageWithTask";
import InsightsPage from "./pages/InsightsPage";
import Profile from "./pages/ProfilePage";
import CreatorSurvey from "./pages/CreatorSurvey";
import CampaignResults from "./pages/CampaignResultsPage";
import CustomContract from "./sections/@dashboard/campaigns/customContract";
import Chat from "./pages/Chat";
import Wishlist from "./pages/WishlistPage"

// ----------------------------------------------------------------------

export default function Router({ loggedIn, setLoggedIn }) {
  const routes = useRoutes([
    {
      path: "/",
      element: loggedIn ? (
        <DashboardLayout />
      ) : (
        <LoginPage setLoggedIn={setLoggedIn} />
      ),
      children: [
        { path: "/dashboard", element: <DashboardAppPage /> },
        {
          path: "/campaigns",
          element: loggedIn ? (
            <CampaignsPage />
          ) : (
            <LoginPage setLoggedIn={setLoggedIn} />
          ),
        },
        {
          path: "/insights",
          element: loggedIn ? (
            <InsightsPage />
          ) : (
            <LoginPage setLoggedIn={setLoggedIn} />
          ),
        },
        {
          path: "/campaign-results",
          element: loggedIn ? (
            <CampaignResults />
          ) : (
            <LoginPage setLoggedIn={setLoggedIn} />
          ),
        },
        {
          path: "/creator-survey",
          element: loggedIn ? (
            <CreatorSurvey />
          ) : (
            <LoginPage setLoggedIn={setLoggedIn} />
          ),
        },
        {
          path: "/gallery",
          element: loggedIn ? (
            <GalleryPage />
          ) : (
            <LoginPage setLoggedIn={setLoggedIn} />
          ),
        },
        {
          path: "/profile",
          element: loggedIn ? (
            <Profile />
          ) : (
            <LoginPage setLoggedIn={setLoggedIn} />
          ),
        },
        {
          path: "/campaign/:id",
          element: loggedIn ? (
            <CampaignDetailsPage />
          ) : (
            <LoginPage setLoggedIn={setLoggedIn} />
          ),
        },
        {
          path: "/campaign/:id/details",
          element: loggedIn ? (
            <CampaignDetailsPageWithTask />
          ) : (
            <LoginPage setLoggedIn={setLoggedIn} />
          ),
        },
        {
          path: "/customContract/:id",
          element: loggedIn ? (
            <CustomContract />
          ) : (
            <LoginPage setLoggedIn={setLoggedIn} />
          ),
        },
        {
          path: "/chats",
          element: loggedIn ? (
            <Chat />
          ) : (
            <LoginPage setLoggedIn={setLoggedIn} />
          ),
        },
        {
          path: "/wishlist",
          element: loggedIn ? (
            <Wishlist />
          ) : (
            <LoginPage setLoggedIn={setLoggedIn} />
          ),
        },
      ],
    },
    {
      path: "login",
      element: <LoginPage setLoggedIn={setLoggedIn} />,
    },
    {
      path: "signup",
      element: <SignupPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
