import { gql } from "@apollo/client";

const GET_BRANDS = gql`
  query getBrands {
    getBrands {
      id
      brandName
      image
      email
      country
      city
      accountStatus
      lastSignInAt
      currentSignInAt
    }
  }
`;

export default GET_BRANDS;
