import { gql } from "@apollo/client";

const ACCEPT_GIFTED_CAMPAIGN = gql`
  mutation influencerAcceptGiftedContract(
    $influencerId: ID!
    $campaignId: ID!
  ) {
    influencerAcceptGiftedContract(
      campaignId: $campaignId
      influencerId: $influencerId
    )
  }
`;

export default ACCEPT_GIFTED_CAMPAIGN;
