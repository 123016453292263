import { gql } from "@apollo/client";

const SHOW_PAID_INTEREST = gql`
  mutation influencerInterestInPaidCampaign(
    $userId: ID!
    $campaignId: ID!
    $influencerQuote: Int!
    $description: String!
    $commissionModel: Boolean
    $influencerQuoteType: String!
    $brandSignature: String
    $brandName: String
    $brandDate: String
    $influencerSignature: String
    $influencerName: String
    $influencerDate: String
    $adminSignature: String
    $adminName: String
    $adminDate: String
  ) {
    influencerInterestInPaidCampaign(
      userId: $userId
      campaignId: $campaignId
      influencerQuote: $influencerQuote
      description: $description
      commissionModel: $commissionModel
      influencerQuoteType: $influencerQuoteType
      brandSignature: $brandSignature
      brandName: $brandName
      brandDate: $brandDate
      influencerSignature: $influencerSignature
      influencerName: $influencerName
      influencerDate: $influencerDate
      adminSignature: $adminSignature
      adminName: $adminName
      adminDate: $adminDate
    )
  }
`;

export default SHOW_PAID_INTEREST;
