import React, { useState } from "react";
import {
  Button,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import GET_WISHLIST from "../graphql/queries/getWishlist"
import { makeStyles, withStyles } from "@mui/styles";
import BrandListingModal from "../sections/@dashboard/wishlist/brandListingModal"
import AddCustomBrandModal from "../sections/@dashboard/wishlist/addCustomBrandModal";
import REMOVE_BRAND_FROM_WISHLIST from "../graphql/mutations/removeFromWishlist"
import UPDATE_WISHLIST_ITEM_STATUS from "../graphql/mutations/updateWishlistItemStatus";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  addWishBtn: {
    marginTop: 10,
    backgroundColor: "#ee2f82",
    color: "#fff",
  },
  editProfileBtn: {
    width: 35,
    height: 35,
    position: "relative !important",
    top: -250,
    right: -140,
    backgroundColor: "#ee2f82 !important",
    color: "#fff !important",
  },
  smallCell: {
    width: "250px !important",
    textAlign: "center !important",
  },
  banner: {
    height: "30vh",
    minHeight: "350px",
    padding: "10px 5px",
    backgroundImage: `url(https://images.unsplash.com/photo-1570475735025-6cd1cd5c779d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8)`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    color: "#2d3436",
    marginBottom: 40,
  },
  avatar: {
    width: "150px !important",
    height: "150px !important",
    boxShadow: "rgba(0, 0, 0, 0.6) 0px 1px 4px",
  },
  socialIcon: {
    padding: 4,
  },
  tableHeader: {
    background: '#ed8ab5', // Change the background color as desired
    color: 'white', // Change the text color as desired
    fontWeight: 'bold', // Make the text bold
  },
});

const MyWishlist = () => {
  const classes = useStyles();
  const [wishlist, setWishlist] = useState([]);
  const currentUser = localStorage.getItem("currentUser");
  const [wishlistPopup, setWishlistPopup] = useState(false);
  const [customPopup, setCustomPopup] = useState(false);
  const [removeFromWishlist, { loading, error }] = useMutation(REMOVE_BRAND_FROM_WISHLIST);
  const [updateWishlistItemStatus] = useMutation(UPDATE_WISHLIST_ITEM_STATUS, {
    refetchQueries: ["getWishlist"],
  });


  useQuery(GET_WISHLIST, {
    variables: {
      influencerId: currentUser,
    },
    onCompleted: (res) => {
      setWishlist(res.getInfluencerWishlist);
    },
  });

  const updateParentWishlist = (newWishlist) => {
    setWishlist(newWishlist);
  };

  const handleRemoveFromWishlist = (wishlistId) => {
    removeFromWishlist({
      variables: {
        wishlistId: wishlistId,
      },
    })
      .then(() => {
        // Assuming the mutation returns a boolean indicating success
        if (true) {
          const updatedWishlist = wishlist.filter((item) => item.id !== wishlistId);
          setWishlist(updatedWishlist);
        }
      })
      .catch((error) => {
        console.error('Error removing brand from wishlist:', error);
      });
  };

  const handleWishlistChange = (wishlistId) => {
    updateWishlistItemStatus({
      variables: {
        wishlistId: wishlistId,
      },
    })
      .then((response) => {
        const updatedWishlist = wishlist.map((item) => {
          if (item.id === wishlistId) {
            return { ...item, collaborated: response?.data?.updateWishlistStatus?.collaborated };
          }
          return item;
        });
        setWishlist(updatedWishlist);
      })
      .catch((error) => {
        console.error('Error updating wishlist status:', error);
      });
  };

  return (
    <Grid item xs container justifyContent="center">
      <Grid
        item
        xs={12}
        lg={10}
        container
        justifyContent="space-around"
        alignItems="center"
        sx={{ minHeight: 400 }}
      >
        <Typography variant="h4"> Wishlist </Typography>
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>
                  <b>Brand Name</b>
                </TableCell>
                <TableCell className={`${classes.smallCell} ${classes.tableHeader}`}>
                  <b>Collaborated Yet</b>
                </TableCell>
                <TableCell align="right" className={`${classes.smallCell} ${classes.tableHeader}`}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wishlist?.map((row) => (
                <TableRow key={row?.id}>
                  <TableCell component="th" scope="row">
                    <Typography variant="body2"> {row?.brand?.brandName || row?.brandName} </Typography>
                  </TableCell>
                  <TableCell className={classes.smallCell}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={row?.collaborated}
                          onChange={() =>
                            handleWishlistChange(row?.id)
                          }
                          name="wishlist-item"
                        />
                      }
                      label={row?.collaborated ? "Yes" : "No"}
                    />
                  </TableCell>
                  <TableCell className={classes.smallCell}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.addWishBtn}
                      onClick={() => handleRemoveFromWishlist(row?.id)} disabled={loading}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Button
          variant="contained"
          onClick={() => setWishlistPopup(true)}
          className={classes.addWishBtn}
        >
          Choose From List
        </Button> */}
        <Button
          variant="contained"
          onClick={() => setCustomPopup(true)}
          className={classes.addWishBtn}
        >
          Add New
        </Button>
      </Grid>
      {wishlistPopup && (
        <BrandListingModal
          open={wishlistPopup}
          setOpen={setWishlistPopup}
          currentUserId={currentUser}
          wishlist={wishlist}
          updateWishlist={updateParentWishlist}
        />
      )}
      {customPopup && (
        <AddCustomBrandModal
          open={customPopup}
          setOpen={setCustomPopup}
          currentUserId={currentUser}
          wishlist={wishlist}
          updateWishlist={updateParentWishlist}
        />
      )}
    </Grid>
  );
}

export default MyWishlist;
