import PropTypes from "prop-types";
import {
  Card,
  Checkbox,
  CardHeader,
  FormControlLabel,
  Grid,
} from "@mui/material";

import { useQuery } from "@apollo/client";
import PROFILE_CHECKLIST from "../../../graphql/queries/profileChecklist";
// ----------------------------------------------------------------------

AppTasks.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppTasks({ title, subheader, list, ...other }) {
  const { data } = useQuery(PROFILE_CHECKLIST, {
    variables: {
      userId: localStorage.getItem("currentUser"),
    },
  });
  const dashboardKey = {
    instagram_insights: data?.myDashboardInsights.instagram_insights,
    tiktok_insights: data?.myDashboardInsights.tiktok_insights,
    gallery: data?.myDashboardInsights.gallery,
    campaigns: data?.myDashboardInsights.campaigns,
  };

  if (data === undefined) return <></>;
  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
        sx={{ marginBottom: 2 }}
      />
      <Grid item xs={12} container justifyContent="space-around">
        {list.map((task) => (
          <TaskItem key={task.id} task={task} status={dashboardKey[task.id]} />
        ))}
      </Grid>
    </Card>
  );
}

// ----------------------------------------------------------------------

TaskItem.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  task: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }),
};
function TaskItem({ task, status }) {
  return (
    <FormControlLabel
      control={<Checkbox checked={status} />}
      label={task.label}
      sx={{
        flexGrow: 1,
        m: 0,
        pointerEvents: "none",
      }}
    />
  );
}
