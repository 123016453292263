import { gql } from "@apollo/client";

const SAVED_SIGNATURE = gql`
query getSavedSignature($campaignId: ID!,$userId: ID!) {
  getSavedSignature(campaignId: $campaignId,userId: $userId) 

}

`;

export default SAVED_SIGNATURE;
