import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { pink } from "@mui/material/colors";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import GET_CAMPAIGN_DETAILS from "../../../graphql/queries/getCampaignDetails";
import S3 from "aws-s3";
import s3_config from "../../../config/s3_config";
import CUSTOM_RESPONSE from "../../../graphql/mutations/influencerRespondWithContract";
import { useNavigate } from "react-router-dom";
import CurrencyType from "../../../components/fields/currencyType";

const S3Client = new S3(s3_config);
const CustomContract = () => {
  const [defaultContract, setDefaultContract] = useState();
  const [currencyType, setCurrencyType] = useState('USD');
  const [extendedBrandContract, setExtendedBrandContract] = useState();
  const [description, setDescription] = useState(null);
  const [influencerQuote, setInfluencerQuote] = useState(0);
  const [agreed, setAgreed] = useState(false);
  const [extendedContract, setExtendedContract] = useState("");
  const [influencerCommissionModel, setInfluencerCommissionModel] = useState(false);

  const navigate = useNavigate();

  const handleAgreement = (event) => {
    setAgreed(event.target.checked);
  };
  const { id } = useParams();
  const { data } = useQuery(GET_CAMPAIGN_DETAILS, {
    variables: { campaignId: id },
  });
  const [acceptOffer] = useMutation(CUSTOM_RESPONSE, {
    onCompleted: () => {
      setAgreed(false);
      navigate("/");
    },
    refetchQueries: [
      "getCandidateCampaignsForInfluencer",
      "getInterestedCampaignsForInfluencer",
      "getInvitedCampaignsForInfluencer",
      "getCampaignsForInfluencer",
    ],
  });
  const loadContract = async () => {
    await fetch(
      "https://authentic-pps.s3.eu-west-2.amazonaws.com/authentic_default_contract.txt"
    ).then((r) => {
      r.text().then((d) => {
        setDefaultContract(d);
      });
    });
  };

  const loadExtendedContract = async (contractLink) => {
    await fetch(contractLink).then((r) => {
      r.text().then((d) => {
        setExtendedBrandContract(d);
      });
    });
  };

  const respondToCampaign = async () => {
    const blob = new Blob([extendedContract], { type: "text/plain" });
    S3Client.uploadFile(blob)
      .then((data) => {
        acceptOffer({
          variables: {
            campaignId: id,
            influencerId: localStorage.getItem("currentUser"),
            influencerQuote: parseInt(influencerQuote),
            influencerQuoteType: currencyType, 
            description: description || " ",
            contract: data.location,
            commissionModel: influencerCommissionModel,  
          },
        });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    loadContract();
    data?.getCampaignDetails?.contract &&
      loadExtendedContract(data?.getCampaignDetails?.contract);
    //eslint-disable-next-line
  }, [data]);

  return (
    <>
      <Grid
        item
        xs={12}
        container
        sx={{ overflowY: "scroll", padding: 2 }}
        justifyContent="space-between"
      >
        <Grid item xs={12}>
          <div style={{display: "flex", marginTop: "5px"}}>
            <CurrencyType currencyType={currencyType} setCurrencyType={setCurrencyType} width={"7%"} />
            <TextField
              variant="standard"
              id="offer"
              label="My Offered Price"
              type="number"
              required
              onChange={(e) => setInfluencerQuote(e.target.value)}
              placeholder="£"
              sx={{ width: 200, marginBottom: 3 }}
            />
            </div>
          <TextField
            fullWidth
            variant="standard"
            id="description"
            label="Description"
            multiline
            rows={4}
            placeholder="Please explain how you can contribute to the campaign"
            onChange={(e) => setDescription(e.target.value)}
            sx={{ marginBottom: 3 }}
          />


          {data?.getCampaignDetails?.commissionModel && (<FormControl sx={{ width: "100%", marginBottom: 4, marginTop: 4 }}>
            <InputLabel
              id="opt-in-for-the-affiliate-commission-model"
              sx={{ color: "#EF2E83", fontSize: "1rem !important" }}
            >
              Opt in for the Affiliate commission model
            </InputLabel>
            <Select
              variant="standard"
              labelId="opt-in-for-the-affiliate-commission-model"
              id="opt-in-for-the-affiliate-commission"
              label="Opt in for the Affiliate commission model"
              value={influencerCommissionModel}
              onChange={(e) =>
                setInfluencerCommissionModel(e.target.value)
              }
            >
              {[{label: 'Yes', value: true}, {label: 'No', value: false}].map((option) => (
                <MenuItem
                  value={option.value}
                  key={option.value + "commission-model" + option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          )}
        </Grid>
        {/* =========================================================================================== */}
        <div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Default Contract Terms</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div dangerouslySetInnerHTML={{ __html: defaultContract }} />
            </AccordionDetails>
          </Accordion>
          {data?.getCampaignDetails?.contract && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Additional Terms by Brand</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  dangerouslySetInnerHTML={{ __html: extendedBrandContract }}
                />
              </AccordionDetails>
            </Accordion>
          )}
        </div>
        {/* =============================================================================================================== */}
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        container
        justifyContent="center"
        sx={{ padding: 2 }}
      >
        <Grid item xs={12} sx={{ height: 500 }}>
          <Typography variant="h5"> Proposed Contract Changes </Typography>
          {/* <TextField multiline rows={5} /> */}
          <ReactQuill
            theme="snow"
            value={extendedContract}
            onChange={setExtendedContract}
            style={{ height: 400 }}
          />
        </Grid>
        <FormControlLabel
          control={
            <Checkbox
              checked={agreed}
              onChange={handleAgreement}
              inputProps={{ "aria-label": "controlled" }}
              sx={{
                color: pink[800],
                "&.Mui-checked": {
                  color: pink[600],
                },
              }}
            />
          }
          label="I have read the terms and agree."
          sx={{
            width: "100%",
            justifyContent: "center",
            backgroundColor: "#000",
            paddingLeft: 3,
            paddingRight: 3,
            marginTop: 2,
            color: "#fff",
          }}
        />
        {agreed && extendedContract === "" && (
          <Typography variant="subtitle" color="error" fullWidth>
            Proposing changes to contract is mandatory. Otherwise, respond to
            campaign from previous page.
          </Typography>
        )}
      </Grid>
      <Button
        fullWidth
        variant="contained"
        sx={{ margin: "auto" }}
        onClick={() => respondToCampaign()}
        disabled={!agreed || extendedContract === ""}
      >
        Submit
      </Button>
    </>
  );
};

export default CustomContract;
