import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  CircleStencil,
  FixedCropper,
  ImageRestriction,
} from "react-advanced-cropper";
import S3 from "aws-s3";
import "react-advanced-cropper/dist/style.css";
import {imageQuality} from "../../components/shared/imageQuality"

import s3_config from "../../config/s3_config";
window.Buffer = window.Buffer || require("buffer").Buffer;



const S3Client = new S3(s3_config);

export const ProfilePicUploader = ({ open, setOpen, setPfp }) => {
  const cropperRef = useRef(null);
  const [profilePic, setProfilePic] = useState("");
  const [disableActions, setDisableActions] = useState(true);

  const handleSave = async () => {
    const croppedImage = cropperRef.current.getCanvas().toDataURL();
    var byteString = atob(croppedImage.split(",")[1]);
    var mimeString = croppedImage.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var dw = new DataView(ab);
    for (var i = 0; i < byteString.length; i++) {
      dw.setUint8(i, byteString.charCodeAt(i));
    }
    const finalImage = new Blob([ab], { type: mimeString });
    S3Client.uploadFile(finalImage)
      .then((data) => {
        setPfp(data.location);
        setOpen(false);
      })
      .catch((err) => {});
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpload = async (file) => {
    const isGoodQuality = await imageQuality(file);

    if (!isGoodQuality) {
      alert('Please upload a better quality image file.');
    }
    else{
      S3Client.uploadFile(file)
      .then((data) => {
        setProfilePic(data.location);
        setDisableActions(false);
      })
      .catch((err) => {});
    }

  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle align="center">Upload Profile Picture</DialogTitle>
      <DialogContent>
        <FixedCropper
          minHeight={400}
          minWidth={400}
          ref={cropperRef}
          src={profilePic || ""}
          stencilSize={{
            width: 300,
            height: 300,
          }}
          stencilComponent={CircleStencil}
          stencilProps={{
            aspectRatio: 6 / 9,
            movable: false,
            resizable: true,
          }}
          imageRestriction={ImageRestriction.stencil}
          className={"cropper"}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          component="label"
          sx={{ marginRight: 4 }}
          disabled={!disableActions}
        >
          Select
          <input
            hidden
            accept="image/*"
            type="file"
            onChange={(e) => handleUpload(e.target.files[0])}
          />
        </LoadingButton>
        <Button onClick={handleSave} disabled={disableActions}>
          Upload
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
