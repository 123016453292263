import { Helmet } from "react-helmet-async";
import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import CampaignDetailBanner from "../sections/@dashboard/campaigns/campaignDetailBanner";
import { CampaignTask } from "../sections/@dashboard/campaigns";

// ----------------------------------------------------------------------

export default function CampaignDetailsPage() {
  const { id } = useParams();

  return (
    <>
      <Helmet>
        <title> Campaigns </title>
      </Helmet>

      <Container>
        <CampaignDetailBanner campaignId={id} />
        <CampaignTask campaignId={id} />
      </Container>
    </>
  );
}
