import { gql } from "@apollo/client";

const GET_INFLUENCER_CAMPAIGN_RESULT = gql`
  query getInfluencerCampaignResult(
    $userId: ID!
    $campaignId: ID!
    $campaignResultStatus: String!
    $campaignResultMedia: String!
  ) {
    getInfluencerCampaignResult(
      userId: $userId
      campaignId: $campaignId
      campaignResultStatus: $campaignResultStatus
      campaignResultMedia: $campaignResultMedia
    ) {
      id
      userId
      campaignId
      campaignResultStatus
      campaignResultMedia
      instaImpressions
      instaLikes
      instaComments
      instaFollowers
      instaStoryViews
      instaReach
      instaPlays
      instaNonFollowers
      instaSaves
      instaShares
      instaProfileVisits
      instaAccountsReached
      instaAccountsEngaged
      instaLinkClicks
      tiktokLikes
      tiktokComments
      tiktokFollowers
      tiktokViews
      tiktokShares
      discountCodeUsed
      discountCode
      tiktokAverageWatchTime
      tiktokWatchFullVideo
      tiktokPlays
      tiktokSaves
      link
    }
  }
`;

export default GET_INFLUENCER_CAMPAIGN_RESULT;
