import React, { useState } from 'react';
import {
  TextField,
  Button,
  Box,
  Modal
} from "@mui/material";
import { useMutation } from "@apollo/client";
import ADD_TO_WISHLIST from '../../../graphql/mutations/addToWishlist';

function AddCustomBrandModal({ open, setOpen, currentUserId, wishlist, updateWishlist }) {
  const [addToWishlist, { loading, error }] = useMutation(ADD_TO_WISHLIST);
  const [brandName, setBrandName] = useState("");

  const handleAddToWishlist = () => {
    addToWishlist({
      variables: {
        brandName: brandName,
        influencerId: currentUserId,
      },
    })
      .then((response) => {
        const updatedWishlist = [...wishlist, response.data.addBrandToWishlist];
        setOpen(false)
        updateWishlist(updatedWishlist);
      })
      .catch((error) => {
        // Handle mutation error here
        console.error('Error adding brand to wishlist:', error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="wishlist-modal-title"
      aria-describedby="wishlist-modal-description"
    >
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
        <h2 id="wishlist-modal-title">Add Brands to Wishlist</h2>
        <TextField
          label="Brand Name"
          value={brandName}
          onChange={(e) => setBrandName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" onClick={handleAddToWishlist}>
          Add
        </Button>
        <Button sx={{marginLeft: 25}} onClick={handleClose}>Cancel</Button>
      </Box>
    </Modal>
  );
}

export default AddCustomBrandModal
