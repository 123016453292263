import PropTypes from "prop-types";
// @mui
import { MenuItem, TextField } from "@mui/material";

// ----------------------------------------------------------------------

GallerySort.propTypes = {
  options: PropTypes.array,
  onSort: PropTypes.func,
};

export default function GallerySort({ options, onSort, sortBy }) {
  return (
    <TextField
      select
      size="small"
      value={sortBy}
      onChange={(e) => onSort(e.target.value)}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
